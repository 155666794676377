<!-- src/pages/Liff/Proj/AbbottAnd/SalesTool.vue -->
<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="headerConfig.page_title"
        :page-image="headerConfig.page_image"
        :page-kv-image="headerConfig.page_kv_image"
        :header-section="headerConfig.header_section"
      />
      <div class="page">
        <div class="sales-tool">
          <h1 class="sales-tool__title mt-3 mb-4">{{ deepGet(moduleConfig, 'text.page_title', '') }}</h1>

          <!-- Step 1: Initial Screen -->
          <div v-if="currentStep === 1" class="sales-tool__step">
            <ShareButton
              @click="handleCameraIsClicked"
              class="sales-tool__button"
              variant="s-btn-outline-primary"
            >
              開啟相機
            </ShareButton>
          </div>

          <!-- Step 2: Scanner -->
          <div v-if="currentStep === 2" class="sales-tool__step">
            <div class="sales-tool__scanner">
              <QRCodeScanner @onScan="handleQrCodeIsScanned" :qrbox="qrbox" ref="qrScanner" />
            </div>
            <input
              class="sales-tool__input"
              v-model="scannedUrl"
              :placeholder="deepGet(moduleConfig, 'text.url_input_placeholder', '')"
            />
            <ShareButton
              @click="handleNextStepIsClicked"
              class="sales-tool__button mt-4"
              variant="s-btn-outline-primary"
            >
            下一步
            </ShareButton>
          </div>

          <!-- Step 3: Result Form -->
          <div v-if="currentStep === 3" class="sales-tool__step">
            <div class="sales-tool__form">
              <div class="sales-tool__radio-group">
                <label v-for="option in radioOptions" :key="option.type" class="sales-tool__radio">
                  <input
                    type="radio"
                    v-model="orderType"
                    :value="option.type"
                    @change="handleOrderTypeChanged(option)"
                  >
                  <span class="sales-tool__radio-label">{{ option.text }}</span>
                </label>
              </div>

              <textarea
                v-model="notes"
                class="sales-tool__textarea mt-3"
                placeholder="請輸入備註..."
                rows="6"
              ></textarea>

              <ShareButton
                @click="handleConfirmIsClicked"
                class="sales-tool__button mt-2 w-100"
                variant="s-btn-outline-primary"
              >
                {{ deepGet(moduleConfig, 'text.confirm_button', '') }}
              </ShareButton>

              <div class="sales-tool__button-group mt-4">
                <ShareButton
                  @click="handleCopyIsClicked"
                  class="sales-tool__button mt-2 w-100"
                  :disabled="!sharingButtonsAreEnabled"
                >
                  {{ deepGet(moduleConfig, 'text.copy_button', '') }}
                </ShareButton>
                <ShareButton
                  @click="handleLineShareIsClicked"
                  class="sales-tool__button mt-2 w-100"
                  :disabled="!sharingButtonsAreEnabled"
                >
                  {{ deepGet(moduleConfig, 'text.line_button', '') }}
                </ShareButton>
              </div>
              <button class="sales-tool__button sales-tool__button--text mt-4" @click="handleReturnIsClicked">返回掃描</button>
            </div>
          </div>
        </div>
      </div>

      <Loading v-if="isCheckingExistence" loading-message="檢查兌換碼中..." backgroundColor="rgba(255, 255, 255, 0.8)"/>
      <Loading v-if="isSavingRedeemCode" loading-message="儲存中..." backgroundColor="rgba(255, 255, 255, 0.8)"/>
    </div>
  </div>
</template>

<script>
import deepGet from "lodash/get";
import { mapGetters, mapActions } from "vuex";
import QRCodeScanner from "@/components/QRCodeScanner.vue";
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Loading from "@/components/Page/Liff/Shared/Loading";
import themeColor from "@/mixins/liff/themeColor";
import liff from "@line/liff";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import LiffChecker from "@/utils/LiffChecker";

export default {
  name: "SalesTool",
  components: {
    QRCodeScanner,
    ShareButton,
    Header,
    Loading,
  },
  mixins: [themeColor],
  data() {
    return {
      themeConfigPage: "coupon",
      currentStep: 1,
      barcode: null,
      qrbox: {
        width: 200,
        height: 200,
      },
      orderType: null,
      notes: "",
      scannedUrl: "",
      shortenedUrl: "",
      isCheckingExistence: false,
      isSavingRedeemCode: false,
      redeemCodeIsSaved: false,
      confirmedOrderType: null,
    };
  },
  computed: {
    ...mapGetters({
      meta: 'liffGeneral/meta',
      themeConfig: 'liffGeneral/themeConfig',
      getModule: 'liffModule/getModule',
    }),
    headerConfig() {
      return this.getModule('liff_coupon')?.module_config || {}
    },
    moduleConfig() {
      return this.getModule('abbott_salestool')?.module_config || {}
    },
    radioOptions() {
      return this.moduleConfig?.radio_options || []
      // return [
      //   {
      //     "text": "杏一下單",
      //     "type": 1,
      //     "tag": "業代轉發Hopar_杏一下單",
      //     "message": "(杏一下單)您好,我是剛才與您聯繫的亞培顧問,請點以下連結加LINE,可獲得最多5罐免費贈罐的優惠喲!優惠券請至杏一/維康兌換(杏一下單)"
      //   },
      //   {
      //     "text": "維康下單",
      //     "type": 2,
      //     "tag": "業代轉發Hopar_維康下單",
      //     "message": "您好,我是剛才與您聯繫的亞培顧問,請點以下連結加LINE,可獲得最多5罐免費贈罐的優惠喲!優惠券請至杏一/維康兌換(維康下單)"
      //   },
      //   {
      //     "text": "未下單",
      //     "type": 0,
      //     "is_default": true,
      //     "tag": "業代轉發Hopar_未下單",
      //     "message": "您好,我是剛才與您聯繫的亞培顧問,請點以下連結加LINE,可獲得最多5罐免費贈罐的優惠喲!優惠券請至杏一/維康兌換(未下單)"
      //   }
      // ]
    },
    defaultOption() {
      return this.radioOptions.find(opt => opt.is_default) || this.radioOptions[0];
    },
    selectedOption() {
      return this.radioOptions.find(opt => opt.type === this.orderType) || this.defaultOption;
    },
    sharingButtonsAreEnabled() {
      return this.redeemCodeIsSaved && this.orderType === this.confirmedOrderType;
    },
  },
  async created() {
    const checker = new LiffChecker(this.$route.params.orgCode);
    await checker.initLiff();
    await this.fetchModule('abbott_salestool');
    this.orderType = this.defaultOption.type;
  },
  methods: {
    deepGet,
    ...mapActions({
      fetchModule: 'liffModule/fetchModule'
    }),
    isValidUrl(string) {
      try {
        const url = new URL(string);
        const hasIdParam = url.searchParams.has('Id');
        if (!hasIdParam) {
          throw new Error('URL must contain Id parameter');
        }
        return true;
      } catch (_) {
        return false;
      }
    },
    isValidLiffRoute(url) {
      return [
        'https://liff.line.me/1655253345', // AbbottAnd production
        'https://liff.line.me/1565710169', // AbbottAnd production
        'https://liff.line.me/2005830164', // Waltily QA
      ].includes(deepGet(url.split('-'), 0));
    },
    loadOrderMessage(option) {
      return `${option.message}\n${this.shortenedUrl}`;
    },
    navigateToStep(step) {
      if (step === this.currentStep) return;

      // Stop scanner if leaving step 2
      if (this.currentStep === 2 && this.$refs.qrScanner) {
        this.$refs.qrScanner.stopScanner();
      }

      this.currentStep = step;

      // Initialize scanner if entering step 2
      if (step === 2 && this.$refs.qrScanner) {
        this.$refs.qrScanner.initializeScanner();
      }

      // Set default message when entering step 3
      if (step === 3) {
        this.notes = this.loadOrderMessage(this.selectedOption);
      }
    },
    async createShortenedUrl(url) {
      try {
        const response = await abbottAndApi.createShortenedUrl(this.$route.params.orgCode, url);
        return response.data;
      } catch (error) {
        console.error('Error creating shortened url:', error);
        throw error;
      }
    },
    async checkRedeemCodeExistence(url) {
      try {
        const response = await abbottAndApi.checkRedeemCodeExistence(this.$route.params.orgCode, {
          code: (new URL(url)).searchParams.get('Id')
        });
        return response.data.exists;
      } catch (error) {
        console.error('Error checking redeem code:', error);
        throw error;
      }
    },
    async saveRedeemCodeForSales(url) {
      this.isSavingRedeemCode = true;
      try {
        const response = await abbottAndApi.saveRedeemCodeForSales(this.$route.params.orgCode, {
          code: (new URL(url)).searchParams.get('Id'),
          type: this.orderType
        });
        return response.data;
      } catch (error) {
        console.error('Error creating redeem code:', error);
        throw error;
      } finally {
        this.isSavingRedeemCode = false;
      }
    },
    handleCameraIsClicked() {
      this.navigateToStep(2);
    },
    async handleQrCodeIsScanned(decodedText) {
      this.barcode = decodedText;
      this.scannedUrl = decodedText;
      if (!this.isValidUrl(this.scannedUrl)) {
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.missing_id_parameter_title', ''),
          text: deepGet(this.moduleConfig, 'text.missing_id_parameter_content', ''),
          confirmButtonText: '確定'
        });
        return;
      }
      if (!this.isValidLiffRoute(this.scannedUrl)) {
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.invalid_liff_id_title', ''),
          text: deepGet(this.moduleConfig, 'text.invalid_liff_id_content', ''),
          confirmButtonText: '確定'
        });
        return;
      }

      try {
        this.isCheckingExistence = true;
        this.shortenedUrl = await this.createShortenedUrl(this.scannedUrl);
        const exists = await this.checkRedeemCodeExistence(this.scannedUrl);
        this.isCheckingExistence = false;
        if (exists) {
          await this.$swal({
            title: deepGet(this.moduleConfig, 'text.scanning_existing_redeem_code_alert_title', ''),
            text: deepGet(this.moduleConfig, 'text.scanning_existing_redeem_code_alert_content', ''),
            confirmButtonText: '確定'
          });
        }
        this.navigateToStep(3);
      } catch (error) {
        this.isCheckingExistence = false;
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.failed_to_create_shortened_url_title', ''),
          text: deepGet(this.moduleConfig, 'text.failed_to_create_shortened_url_content', ''),
          confirmButtonText: '確定'
        });
      }
    },
    async handleNextStepIsClicked() {
      if (!this.scannedUrl || !this.isValidUrl(this.scannedUrl)) {
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.missing_id_parameter_title', ''),
          text: deepGet(this.moduleConfig, 'text.missing_id_parameter_content', ''),
          confirmButtonText: '確定'
        });
        return;
      }
      if (!this.isValidLiffRoute(this.scannedUrl)) {
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.invalid_liff_id_title', ''),
          text: deepGet(this.moduleConfig, 'text.invalid_liff_id_content', ''),
          confirmButtonText: '確定'
        });
        return;
      }

      try {
        this.isCheckingExistence = true;
        this.shortenedUrl = await this.createShortenedUrl(this.scannedUrl);
        const exists = await this.checkRedeemCodeExistence(this.scannedUrl);
        this.isCheckingExistence = false;
        if (exists) {
          await this.$swal({
            title: deepGet(this.moduleConfig, 'text.scanning_existing_redeem_code_alert_title', ''),
            text: deepGet(this.moduleConfig, 'text.scanning_existing_redeem_code_alert_content', ''),
            confirmButtonText: '確定'
          });
        }
        this.navigateToStep(3);
      } catch (error) {
        this.isCheckingExistence = false;
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.failed_to_create_shortened_url_title', ''),
          text: deepGet(this.moduleConfig, 'text.failed_to_create_shortened_url_content', ''),
          confirmButtonText: '確定'
        });
      }
    },
    async handleConfirmIsClicked() {
      try {
        await this.saveRedeemCodeForSales(this.scannedUrl);
        this.redeemCodeIsSaved = true;
        this.confirmedOrderType = this.orderType;
      } catch (error) {
        console.error(error)
        this.$swal({
          icon: 'error',
          title: deepGet(this.moduleConfig, 'text.failed_to_save_redeem_code_title', ''),
          text: deepGet(this.moduleConfig, 'text.failed_to_save_redeem_code_content', ''),
          confirmButtonText: '確定'
        });
      }
    },
    handleCopyIsClicked() {
      navigator.clipboard.writeText(this.notes)
        .then(() => alert('已複製到剪貼簿'))
        .catch(err => console.error('複製失敗:', err));
    },
    async handleLineShareIsClicked() {
      try {
        if (!liff.isApiAvailable('shareTargetPicker')) {
          alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
          console.warn('share target picker api not available.');
          return;
        }

        const message = {
          type: 'text',
          text: this.notes
        };

        const res = await liff.shareTargetPicker([message], { isMultiple: true });
        if (res) {
          console.log('Message sent successfully');
        }
      } catch (error) {
        console.error('Error sharing message:', error);
        alert('分享訊息失敗');
      }
    },
    handleReturnIsClicked() {
      this.navigateToStep(1);
      this.barcode = null;
      this.scannedUrl = "";
      this.orderType = this.defaultOption.type;
      this.notes = "";
      this.confirmedOrderType = null;
      this.redeemCodeIsSaved = false;
    },
    handleOrderTypeChanged(option) {
      this.notes = this.loadOrderMessage(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.s-layout {
  min-height: 100vh;
  background-color: var(--primary-color);
}

.s-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page {
  flex: 1 1 auto;
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.sales-tool {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__step {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }

  &__button {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;

    &--text {
      background: none;
      border: none;
      color: #6c757d;
      width: auto;
      margin: 0 auto;
      display: block;

      &:hover {
        color: #343a40;
        text-decoration: underline;
      }
    }
  }

  &__scanner {
    width: 500px;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    margin: 0 auto 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }

  &__form {
    width: 100%;
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__radio {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
    cursor: pointer;

    input[type="radio"] {
      width: 20px;
      height: 20px;
    }
  }

  &__textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    resize: none;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }

  &__button-group {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
</style>
